export const environment = {
    env: 'dev',
    production: false,
    dev: true,
    local: false,
    apiUrl: 'https://selfiebot-api-srsftah7aq-uc.a.run.app/api',
    apiUrlAuth: 'https://selfiebot-api-srsftah7aq-uc.a.run.app/api/login',
    firebaseConfig: {
        apiKey: 'AIzaSyDTWJuGjHqWiLSMVsuq1bo-G_05DlImOoA',
        authDomain: 'selfiebot-dev-aa26c.firebaseapp.com',
        projectId: 'selfiebot-dev-aa26c',
        storageBucket: 'selfiebot-dev-aa26c.appspot.com',
        messagingSenderId: '781509249521',
        appId: '1:781509249521:web:63f94b247ecc67c42cdc50',
        measurementId: 'G-XF3HE6339W'
    },
}

